<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("managecompany") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->

        <div class="row d-flex justify-content-center">
          <div class="col-sm-10 mb-4">
            <label
              for="secondaryProductCode"
              class="form-label col-sm-10 required"
              >{{ $t("logo_img") }}</label
            >
            <img :src="form.logo_img" />
            <div class="col-sm-10">
              <input
                type="file"
                accept="image/*"
                id="file"
                ref="logo_img"
                v-on:change="submitfileMain('logo_img')"
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("company_code") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.company_code"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("company_name") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.company_name"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("full_name") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.full_name"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("address") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.address"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("moo") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.moo"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("subdistrict") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.sub_district"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("district") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.district"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("province") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.province"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("postcode") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.postcode"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("tax_id") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.tax_id"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("bank_no") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.bank_no"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("bank_name") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.bank_name"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("bank_account_name") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.bank_account_name"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label for="secondaryProductCode" class="form-label col-sm-10">{{
              $t("payment_img")
            }}</label>
            <div class="col-sm-10">
              <input
                v-model="form.payment_img"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
              />
            </div>
          </div>
        </div>

        <!-- <div class="col-sm-5 mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >เลือกบริษัท</label
            >
            <select
              v-model="form.companyId"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
              :disabled="form.role == 'SUPER_ADMIN' ? false : true"
              required
            >
              <option v-for="item in companyItems" :key="item" :value="item.id">
                {{ item.company_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >เลือกสาขา</label
            >
            <select
              v-model="form.branchId"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
              :required="form.role == 'SUPER_ADMIN' ? false : true"
            >
              <option v-for="item in branchItems" :key="item" :value="item.id">
                {{ item.code }} - {{ item.short_name }} - {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >เลือก Warehouse</label
            >
            <select
              v-model="form.warehouseId"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
              :required="form.role == 'SUPER_ADMIN' ? false : true"
            >
              <option
                v-for="item in warehouseItems"
                :key="item"
                :value="item.id"
              >
                {{ item.warehouse_code }} - {{ item.warehouse_short_name }} -
                {{ item.warehouse_full_name }}
              </option>
            </select>
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import whApi from "@/api/warehouse/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  setup() {
    document.title = "CHOMTHANA | เพิ่มประเภทสินค้า";
  },
  data: () => ({
    loading: false,
    isSubmit: false,

    form: {
      name: "",
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,

    typeProduct: [],
    companyItems: [],
    branchItems: [],
    warehouseItems: [],
    roleItems: [
      { code: "SUPER_ADMIN", name: "SUPER_ADMIN" },
      { code: "ADMIN", name: "ADMIN" },
      { code: "EMP_1", name: "พนักงาน1" },
      { code: "EMP_2", name: "พนักงาน2" },
      { code: "EMP_3", name: "พนักงาน3" },
    ],
  }),

  created() {
    // this.getMainProduct();
    // this.getAllCompany();
    // this.getAllBranch();
    // this.getAllWarehouse();
  },
  watch: {
    // "form.companyId"(val) {
    //   console.log("form.companyId", val);
    //   this.getAllBranch(val);
    // },
    // "form.branchId"(val) {
    //   this.getAllWarehouse(val);
    // },
  },
  methods: {
    async submitfileMain(questionNo) {
      let responseData = [];
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        console.log("auth", auth);

        responseData = await userApi.company.upload(formData, auth);

        this.form.logo_img = responseData.data.path;
      }
    },
    // async getAllCompany() {
    //   this.loading = true;
    //   let responseData = [];
    //   try {
    //     responseData = await userApi.company.getAll();
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.companyItems = responseData.data;
    //     this.form.companyId = responseData.data[0].id;
    //   }
    //   this.loading = false;
    // },
    // async getAllBranch(companyId) {
    //   this.loading = true;
    //   let responseData = [];
    //   try {
    //     responseData = await whApi.branch.getAll(companyId);
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.branchItems = responseData.data;
    //   }
    //   this.loading = false;
    // },
    // async getAllWarehouse(branchId) {
    //   this.loading = true;
    //   let responseData = [];
    //   try {
    //     responseData = await whApi.warehouse.getAll(branchId);
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.warehouseItems = responseData.data;

    //     this.loading = false;
    //   } else {
    //     this.loading = false;
    //     Swal.fire({
    //       icon: "error",
    //       title: "เกิดข้อผิดพลาด",
    //       text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
    //     });
    //   }
    // },
    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isValid) {
        this.loading = true;

        this.form = {
          ...this.form,
        };

        createResponse = await userApi.company.create(this.form);
        if (createResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: `${this.$t("addsuccess")} `,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/config/company/index",
              query: {},
            });
          });
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: `${createResponse.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
      // console.log('submit');
    },

    checkFormIsEmpty() {
      // !this.form.code
      //   ? (this.isValid = false)
      //   :
      !this.form.company_code
        ? (this.isValid = false)
        : !this.form.company_name
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/config/company/index",
        query: {},
      });
    },
  },
};
</script>
